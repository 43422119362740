/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesHelm: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Helm (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_helm.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Helm (Treasure) Guide & Review</h1>
          <h2>
            A guide & review for Helm (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>20/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Helm (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="helm-treasure"
                  enablePopover
                />
              </div>
              <p>
                Helm. The first banner in a game we’ve played for 2 years in the
                making. Do you remember the choices you had to make throughout
                your time in Nikke? Regrets of using Golden Mileage on some
                Nikke you never even used. You may have joined new unions, old
                friends may have quit and new friendships were formed. Different
                Nikkes were invested into - be it waifu or meta, with some
                regrets sinking in deep as a unit dropped out of meta as we wait
                for skill resets.. Well, one such unit was Helm. Our lonely
                admiral. She’s been with us day 1, has been with us through
                thick and thin in so many battles (21 Raid seasons to be exact),
                our companion in the ever hated Elysion Tower, always being a
                decent, mostly niche supporter… until NOW!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Helm’s favorite item skills make her one of the strongest, if
                not the strongest, Nikke in this game. Insane burst generation
                for both PvE and especially PvP, making her the new{' '}
                <strong>TOP TIER PvP unit</strong>, constant heals and extremely
                high synergy with the King of the Kingdom: Crown, a higher
                attack damage buff to allies, and best of all: a new meta Water
                DPS! At base, her DPS is around Quency: Escape Queen’s damage.
                However, she scales much better with supporters than QEQ does
                since she does not have so many bloated multipliers (i.e. her
                skills do not dilute any buffs from allies since she does not
                have insanely high self buffs which units like e.g. Asuka have).
              </p>
              <p>
                Even if you don’t want to build her as DPS, you can expect a
                free 27% damage increase with small to huge healing by just
                putting her in your team, while she fills your burst bar around
                3 seconds right after full burst ends. An ultimate unit for
                bossing, a force to be reckoned with. The Naga replacement in
                Crown teams for when the boss does not have core! An ultimate
                pick for Elysion tower, insane Burst Generation! An ultimate
                pick for PvP, since she has the highest burst generation in the
                game! A jack of all trades, a master of one (Burst Generation),
                and a very spicy Favorite Item story: Helm is a unit every
                account should aim to SR15 and Phase 3!
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                <strong>YES!</strong> She should be the top priority to upgrade
                in Season 3 of Favorite Items. In Helm lies a new usable Meta
                Unit in all game modes! While it does take time to collect
                materials and upgrade in this new system, we recommend getting
                to Phase 1 for the added Burst Gen she provides in PvP, Phase 2
                for her supportive power to be competitive enough to matter, and
                Phase 3 to further improve her DPS! If you are aiming to go for
                other treasures as well, we recommend getting Helm to at least
                Phase 2 on her favorite item to maximise her supportive
                capabilities.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />

              <StaticImage
                src="../../../images/nikke/reviews/helm_guide_1.webp"
                alt="Kit"
              />
              <h5>Frontline Command [Phase 1]</h5>
              <blockquote>
                <p>
                  ■ Activates when the last bullet hits the target. Affects all
                  allies.
                </p>
                <p>Critical Rate of normal attacks ▲ 14.64% for 5 sec.</p>
                <p>
                  ■ Activates when attacking with Full Charge. Affects all
                  allies.
                </p>
                <p>Recovers 0.59% of caster's final Max HP.</p>
                <p>Fills Burst Gauge by 14.31%.</p>
              </blockquote>
              <Alert variant="primary">
                <p>
                  <i>
                    New: She heals her allies based on her own Max HP and fills
                    the burst meter by a fixed amount every full charge shot.
                  </i>
                </p>
              </Alert>
              <p>
                The heal is very small. To help you understand how small it is,
                we can use Naga as an example, who’s also a weak healer compared
                to other healers in-game. Naga can heal 9.58% of her Max HP
                every 5 shots to 3 targets, while Helm will do only 2.95% which
                is pretty much one third of Naga’s heal, and also Helm has at
                least 11% less HP than Naga, and her 5 shots require longer than
                5 shots of Naga too.{' '}
              </p>
              <p>
                The good part of this heal is consistent, and because it
                triggers per instance, you can use Helm to prolong some
                defensive buff, for example Crown’s S2 which required her to be
                healed to trigger. Or Nero’s S1 who gives her an insane amount
                of damage reduction every time she’s healed.
              </p>
              <p>
                The strongest part of this skill lies in the Fill Burst Gauge.
                By shooting a full charge, Helm will increase your burst meter
                by an amount. This amount is doubled on Boss/Targets, allowing
                her to fill the whole bar with just 3 shots alone (Require S1 lv
                10 and you control her), not to mention the help of other 4
                allies. In Nikke, burst is the most important time frame, we
                want to burst as soon as possible (if CDR can allow it), but
                some teams just have very slow burst gen. Helm will fix all of
                this. No more tapping or finding weird spots to gain more burst.
                Just let her auto and you fill your bar before your burst can
                even cooldown.
              </p>
              <Row className="video">
                <Col xs={12} lg={4}>
                  <YoutubeEmbed embedId="olwOoeIUuXY" />
                </Col>
              </Row>
              <p>
                <i>
                  The Helm in the video only has S1 at lv 8, hence she needs 4
                  shots.
                </i>
              </p>
              <h5>Fire Away [Phase 2]</h5>
              <blockquote>
                <p>■ Affects all allies.</p>
                <p>Damage to Interruption Parts ▲ 3.08% continuously.</p>
                <p>■ Activates when entering Full Burst. Affects all allies.</p>
                <p>Attack Damage ▲ 27.87% for 10 sec.</p>
                <p>
                  ■ Activates when hitting a target with Full Charge. Affects
                  the target.
                </p>
                <p>Deals 178.98% of final ATK as additional damage.</p>
              </blockquote>
              <Alert variant="primary">
                <p>
                  <i>Her Attack Damage buff goes from 11.85% =&gt; 27.87%</i>
                </p>
                <p>
                  <i>New: Deal extra damage when you fully charge.</i>
                </p>
              </Alert>
              <p>
                The extra damage is just okay. It has some uses in PVP, where
                you can threaten Position 1 or 5 (depending on ATK or DEF)
                before your enemy can burst, but overall the damage is not that
                impressive. It’s just another incentive to let her auto, because
                fast tapping her feels pretty dumb when you not only lose her
                burst gen, but also lose extra damage.
              </p>
              <p>
                The increased Attack Damage buff, on the other hand, is more
                than welcome. Most meta supporters now can buff teams around 40
                ~ 80% damage buff, so the 27% is not something amazing, but do
                note that Helm is not a supporter. She’s a healer who can buff
                team (sounds like Blanc) but also can deal meaningful DPS
                overtime too.
              </p>
              <p>
                Because her S2 deals extra damage, so it also helps with burst
                gen too. Pushing her treasure to Phase 2 will turn her into the
                highest burst gen in PVP right now, as you can see with this
                table.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/helm_guide_2.webp"
                alt="Kit"
              />
              <p>
                <i>Faster than Jackal is not a normal feat, at all</i>
              </p>
              <h5>Aegis Cannon [Phase 3]</h5>
              <p>
                When Burningum heard that Aegis Cannon was just a meme, he
                upgraded it. Now it returns, becoming a force to be reckoned
                with.
              </p>
              <blockquote>
                <p>■ Affects the enemy with the highest ATK.</p>
                <p>Deals 8236.8% of final ATK as damage.</p>
                <p>■ Affects all allies.</p>
                <p>Recovers 54.45% of attack damage as HP for 10 sec.</p>
                <p>■ Affects self.</p>
                <p>Charge Damage Multiplier ▲ 158.4% for 10 round(s).</p>
              </blockquote>
              <Alert variant="primary">
                <p>
                  <i>Damage goes from 1237.5% to 8236.8%</i>
                </p>
                <p>
                  <i>New: She gains more charge damage for next 10 shots.</i>
                </p>
              </Alert>
              <p>
                Phase 3 is purely for her own DPS, that’s why if you don’t plan
                to use her as a DPS, you don’t need to rush this phase. Let’s
                break down the skill.
              </p>
              <p>
                Firstly, Helm’s burst gains way more damage multiplier. To be
                exact, she gains 565% damage by just upgrading this skill. If we
                compare it with highest burst multiplier in this game which are
              </p>
              <ul>
                <li>Snow White burst: 5000% ATK</li>
                <li>Laplace Treasure version: 8105% ATK</li>
                <li>XMaiden: 16473.6% ATK</li>
                <li>Cinderella: 16549.2% ATK</li>
              </ul>
              <p>
                Those skills are considered high burst damage, with Laplace and
                Snow White having more edge when you can pierce enemies. Looking
                at how Shift Up avoids pierce parts these days, we can safely
                assume for now that single target instance of damage is quite
                meta right now. Her burst won’t be as absurd as Cinderella, but
                it can easily overcome XMaiden (because Maiden needs to stack
                her MP). Do note that Helm burst is not just about damage - it
                also gives lifesteal that pretty much makes your team immortal
                during that window.
              </p>
              <p>
                The charge damage part is a bit tricky. We have another
                character giving this buff too - That’s Admi (and also the
                favorite items for Sniper Rifles). The number means if your Helm
                has 250% charge damage, this burst won’t make it become 250% +
                158.4%, it will become 250% x (1 + 158.4%) = 646% charge damage,
                making this charge damage buff quite effectively on her.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Helm is a jack of all trades — a healer packed with
                damage-boosting capabilities and burst gen potential. The
                easy-to-use not-terrain-reliant burst gen allows her to fit in
                teams that struggle with burst generation. For this reason, we
                can consider her an alternative off-burst unit over Naga when
                paired with Crown.
              </p>
              <p>
                However, in the overall meta, we have to focus on higher
                deficits, where our stat is reduced. While Helm's ATK DMG is
                substantial and she can also trigger Crown's passives, it will
                be hard to justify sacrificing Naga's insane core damage for
                her. Naga, overall, offers more damage in most situations.
                However, your mileage might vary as, depending on your DPS and
                your investment on Naga (skills/cores/CP for padding), you might
                deal more damage with one or another. For most people, it is far
                more likely their Helm will have a higher CP, thus less penalty,
                but this does not always mean increased damage.
              </p>
              <p>
                In the tryhard meta, she is a suitable replacement for players
                that do not have Naga invested into, but since core is always
                prevalent in Campaign, Helm unfortunately cannot compete with an
                invested Naga. With units like Alice and Red Hood having nearly
                permanent spots in Campaign, they take over the job of Burst
                Generation, leaving Helm’s main point in the dust. Helm in
                higher deficits is slightly less optimal to use than Naga.
                However, she is close enough to be a decent enough replacement
                for the majority of the playerbase. On some stages, she can
                prove to be better than Naga! If you're looking to try and auto
                at Higher Deficits, there is probably no better unit to place in
                the off burst slot than an invested Helm with a Phase 2 Favorite
                Item!
              </p>
              <h5>
                Bossing - <strong className="ss">SS</strong>
              </h5>
              <p>
                On a single target boss, HelmT at Phase 3 shines significantly.
                Her burst has a very high damage nuke, and there is an added
                value of extremely high charge damage buffs afterward that allow
                her to deal devastating damage. She can generate way too much
                burst gen to fill in your burst bar, and on water weak enemies
                (without core, duh), she should be one of the top Water DPS, if
                not the best (depends on Overload gear effects and skill
                investments). And not just that, bursting with her means that
                she allows all of the team to heal up back to full! And if you
                thought that’s all, it isn’t! On bosses without core or very low
                core uptime, whatever element the boss is, HelmT at Phase 2
                should be the best off-burst unit in the Crown team to buff your
                main B3s.
              </p>
              <h5>
                PVP - <strong className="sss">SSS</strong>
              </h5>
              <p>
                The power HelmT at Phase 2 has in generating burst (it’s just
                too much burst gen) alone guarantees her to be at least SS. Then
                she buffs, snipes hard, and allows for stall. Her damage is so
                stupid that you can run some specific comps and just snipe their
                DPS at 2 RL, as well as stall the enemies. She allows for a ton
                of new 2RL comps which used to be 2.5~3RL before her. Insane
                burst gen! She is our new entry in the SSS tier, and is here to
                stay in it. HelmT is a must have for Champions Arena, SP Arena,
                and can be a solid option for Rookie Arena as well.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                As an auto character, Helm doesn’t require much ammo (because SR
                takes forever to charge a shot). You can go with a charge speed
                route instead to help her in PVP. You can even go with a Crit
                Rate route to help her nuke bonk harder too.
              </p>
              <ul>
                <li>
                  <strong>Ideal:</strong> 4x Ele, 4x ATK
                </li>
                <li>
                  <strong>Filler:</strong> 4x Crit Rate/Damage, 4x Charge Speed,
                  1× Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Meta
                </li>
              </ul>
              <p>
                Charge Speed is meta for PvP. Crit Rate/Damage is more of a PvE
                route. 1× Ammo with a value of 60%+ can help for burst rotations
                as she gets 10 ammo, which allows her to shoot 7 bullets during
                full burst, and 3 ammo to get to the next burst rotation!
              </p>
              <h5>Skill Investments</h5>
              <p>
                Helm is so easy to build, in many ways. You can use her as a PVP
                unit which only needs a high skill 1, medium skill 2 and low
                burst. Or you can run her as a non burst buffer which means only
                skill 2 is required. Of course, you can go full build Helm at
                10/10/10 too.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Pour as many skill books as you can here if you want to put
                    her as battery in PVP. For PVE, she’s fine at 7, heck, even
                    4.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    This skill gives an Attack Damage buff while giving Helm
                    extra DPS. Good skill to upgrade, can stop at 7 first then
                    finish to 10 later on when one has extra skill books
                    available.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Do you want to bonk a Rapture? (It doesn’t have to be a
                    Rapture!) Go as high as possible to improve her performance
                    in PvE.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="helm-treasure" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_7_sm.png"
                alt="Guide"
              />
              <p>
                For PvP: Her burst gen is so huge that Quantum becomes very
                impactful on her.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                For PvE: Typical Cube for DPS. Slap it on her if you want to go
                DPS build.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Aegis Cannon, Fire!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>or</p>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Bossing Team. The Water DPS team features Phase 3 HelmTr as its
                main DPS. Super strong on single target water weak bosses.
              </p>
              <h5>Team #2: Toss a coin to your Admiral, Beat the Harvester!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A very few still have Helm at Phase 3 Treasure. In cases where
                players only have Phase 2 Helm Treasure, let's stick with Helm
                as non burst support. This team can kill harvester AI with ease
                once you have decently invested OL and skills
              </p>
              <h5>Team #3: Elysion Tower Meta</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 31</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>or</p>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="marciana" enablePopover />
                    <NikkeCharacter mode="icon" slug="diesel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Redefining burst generation in Elysion Tower, Helm really does
                it all! She can even act alongside Mast Anchor to provide
                immense Heal for the team as RRH destroys the enemy raptures.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>How fast can you go?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="laplace" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                2 RL comp that instantly swipes everything once Cinderella
                bursts.
              </p>
              <h5>Can you get past it?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Thanks to Helm, we now can even push the Noise Noah XXX combo
                into 2 RL. This comp is just insane. It has stalls, it has a
                huge nuke, and it has huge follow-up attacks from Cinderella
                too.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Highly flexible, can be used in any team, as a DPS,
                          Offensive Support or Defensive Healer. Pick your
                          poison.
                        </li>
                        <li>Very strong Water DPS once you fully build her.</li>
                        <li>
                          Provides a decent Attack Damage buff for allies.
                        </li>
                        <li>What the hell is that burst gen?</li>
                        <li>
                          Strongest partner for Crown when a boss has no Core or
                          low Core uptime.
                        </li>
                        <li>
                          Is she sitting on the Commander’s **** in that
                          favorite item image!?
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Super expensive. Good luck upgrading her doll, needs
                          SR 15 first.
                        </li>
                        <li>
                          And then she needs a 10/10/10 in skills to reach her
                          peak.
                        </li>
                        <li>
                          And then she needs OL gear investments since she is a
                          DPS as well.
                        </li>
                        <li>
                          Her supportive buffs are not much, hence her position
                          as an off-burst pair with Crown may not last long.
                        </li>
                        <li>
                          You shouldn’t watch her Treasure lobby screen in
                          public probably, just saying.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesHelm;

export const Head: React.FC = () => (
  <Seo
    title="Helm (Treasure) Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Helm (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
